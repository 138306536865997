html, body {
  font-family: 'Play', sans-serif;
  font-size: 100%;
  width: 100%;
  height: 100%; }
@import url('https://fonts.googleapis.com/css?family=Play:400,700&subset=latin-ext');

@import url('https://fonts.googleapis.com/css?family=Oswald:400,700&subset=latin-ext');


// edit save button
.button {
  background-color: #55c13a !important;
  padding: 10px 45px !important;
  margin-top: 10px !important;
  font-size: 19px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important; }


.downrow_nadpis-2 {
  text-align: center;
  font-weight: bold;
  position: relative;
  color: black;
  margin-bottom: 30px; }

// HEADER ----------------------------------------
.header_bg {
  position: relative;
  padding: 0; }

.auticka {
  width: 100%; }

.riso_auticka {
  width: 100%;
  display: none; }

.tutu_auticka {
  width: 100%; }

.logo {
  position: absolute;
  top: 4.5%;
  left: 3%;
  width: 16%; }
.part_1:hover {
  opacity: .8; }
.part_2:hover {
  opacity: .8; }
.part_3:hover {
  opacity: .8; }
.part_4:hover {
  opacity: .8; }

.el_logo {
  position: absolute;
  width: 16%;
  top: 3.7%;
  left: 3%; }

.tirak {
  position: absolute;
  width: 33%;
  top: 17%; }

.downrow {
  width: 100%;
  z-index: -1; }

.downrow_nadpis {
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 71.8%;
  font-weight: 600; }

.odvoz {
  position: absolute;
  top: 82.65%;
  left: 18.5%;
  width: 20%;
  &:hover {
    opacity: 0.92; } }

.zemne {
  position: absolute;
  left: 38.5%;
  top: 82.65%;
  width: 20%;
  margin-left: 1.5%;
  &:hover {
    opacity: 0.92; } }

.prenajom {
  position: absolute;
  left: 58.5%;
  top: 82.65%;
  width: 20%;
  margin-left: 3%;
  &:hover {
    opacity: 0.92; } }

.iny_odvoz, .iny_zemne, .iny_prenajom {
  &:hover {
    opacity: 0.92; } }
.odvoz-2, .zemne-2, .prenajom-2 {
  &:hover {
    opacity: 0.92; } }

.resp_butons {
  display: none; }

.ponukaka {
  text-align: center;
  color: blue;
  font-weight: 900;
  position: absolute;
  top: 5%; }

.ponukos {
  margin-bottom: -11%; }

// NAVBAR --------------------------------------------
.navbar {
  background-image: none;
  background-color: #231f20;
  text-align: center;
  border: none;
  position: absolute;
  left: 21%;
  top: 5.1%;
  line-height: 1.9;
  //width: 78%
  min-height: 5.1%;
  margin-bottom: 0;
  //z-index: 99999
  .navbar>li {
    transition: 0.2s;
    border-left: 1px solid #434343; } }

.navbar li {
 }  //border-left: 1px solid #434343


.navbar-nav>li>a {
  padding-top: 5px;
  padding-bottom: 5px;
 }  //line-height: 290%

.navbar-collapse {
  padding-left: 0;
  padding-right: 0; }

//#medzery
//  width: 2em

#medzera {
  width: 2.3em;
 }  //border-left: none

.navbar-default .navbar-toggle {
  border-color: #231f20;
  background-color: #231f20;
  &:hover {
    background-color: #231f20; } }

.navbar-default .navbar-toggle .icon-bar {
  background-color: #ffffff; }

.navbar li {
  background-color: #231f20; }


.collapse.in, .collapsing {
  position: absolute;
  z-index: 99;
  right: 2%; }

.navbar-default .navbar-collapse {
  border: none; }


// children ------------------------------------
.navbar {
  li:nth-child(2) {
    > a {
      &:after {
        content: "\f0d7";
        font-family: 'FontAwesome';
        padding-left: 12%; } }
    li {
      a:after {
        font-family: Play, sans-serif;
        content: " "; } } } }

.navbar li:last-child a {
  &:after {
    display: none; } }

.navbar li:nth-child(6) a {
  &:before {
    content: "\f230";
    font-family: 'FontAwesome';
    padding-right: 12%; } }

.navbar li:nth-child(7) {
  border: none;
  &:hover {
    background-color: #a70002 !important; } }

.navbar li:nth-child(8) a {
  //background-color: #eb2024 !important
  text-transform: uppercase;
  font-weight: 600; }

.mailme, .callme {
  background-color: white !important;
  color: #000000 !important;
  &:hover {
    background-color: #d2cece !important; } }

.news a {
  &:before {
    content: "\f230";
    font-family: 'FontAwesome';
    padding-right: 12%; } }

.jednat {
  border-left: none !important;
  background-color: #eb2024 !important;
  a {
    font-weight: bold !important;
    &:hover {
      transition: 0.2s !important;
      background-color: #a70002 !important; } }
  //&:hover
  //  background-color: #a70002 !important
 }  //  transition: 0.3s

.jednat a {
  &:after {
    display: none; } }


.activer {
  background-color: #eb2024; }

// hoover, focus, active effects --------------------------------------------------------
.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
  color: #ffffff;
  background-color: #eb2024; }

.navbar-default .navbar-nav>li>a, .navbar-default .navbar-text {
  color: white;
  height: 41px; }

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.open>a {
  color: #ffffff;
  background-color: #eb2024;
  background-image: none; }

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
  color: #ffffff;
  background-color: #eb2024;
  background-image: none; }

.navbar-default .navbar-nav>li>a, .navbar-default .navbar-text {
  transition: 0.2s; }



// dropdown ---------------------------------------------
.navbar-nav li:hover .menu_level_1 {
  display: block; }

.menu_level_1:hover {
  display: block; }

.menu .active>a {
  background-color: #eb2024; }

.menu_level_1 .active {
  background-color: #eb2024; }

.menu_level_1 a {
  display: block;
  max-width: 100%;
  max-height: 100%; }

.menu_level_1 {
  display: none;
  position: absolute;
  background-color: #fff;
  /*box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)*/
  min-width: 12em;
  z-index: 9999;
  color: #777;
  @include transition();
  padding-left: 0;
  margin-top: 1.1%;
  > li {
    text-decoration: none;
    background-color: #434343;
    list-style: none;
    padding-left: 0;
    text-align: left;
    padding-left: 8%;
    border-bottom: 1px solid #595959;
    padding-top: 3%;
    padding-bottom: 3%;
    &:hover {
      background-color: #eb2024;
      cursor: pointer;
      border-bottom: 1px solid #595959; } } }

.menu_level_1 a {
  color: #ffffff;
  text-decoration: none; }

.navbar-header {
  margin-left: 2%; }
.partneri {
  h3 {
    margin-top: 40px;
    margin-bottom: 30px;
    font-weight: bold; } }
.odstup {
  margin-top: 30px; }

// SLIDER ---------------------------------------------------
.fadecovers {
  //display: none
  //text-align: center
  color: #ffffff;
  font-weight: 900;
  font-family: 'Oswald', sans-serif;
  font-size: 20px; }

//@font-face
//  font-family: 'myBebas'
//  src: url('fonts/BebasNeue.otf')
//  font-weight: normal
//  font-style: normal


.cover {
  position: absolute;
  //width: 20%
  top: 19%;
  left: 37%;
 }  //font-family: "myBebas","Ubuntu",sans-serif !important



.cover h1 {
  font-size: 400%;
  margin-bottom: -7%; }

.cover h2 {
  font-size: 300%; }

.zip h1 {
  margin-bottom: -20%; }

.zip h2 {
  margin-left: -27%; }

.rip h1 {
  margin-bottom: -14%; }

// OBSAH STRANKY -------------------------------------------

//.main-bar
//  position: relative
//  top: -3em

//#main
//  margin-bottom: -6%

.menu-content-edit {
  margin-top: -1%; }

//.main-bar h1
//  text-align: center
//  font-weight: 600
//  color: white
//  font-size: 273%
//  text-transform: uppercase
//  position: relative
//  top: -3em

.content-web {
  padding-top: 0 !important; }

.texty {
  margin-bottom: 4%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
 }  //margin-top: -6%

//.menu-content-edit
//  margin-bottom: 4%

.obsah {
  margin-top: 4.5%;
  text-align: center;
  padding: 0 9%; }

.obsah h3 {
  font-weight: 600;
  margin-top: 40px; }

.red {
  color: #eb2024; }

.slovensko {
  padding: 0; }

.mapa {
  width: 100%; }

.zoom_mapa {
  width: 100%;
  display: none; }

.partneri {
  margin-bottom: 3%;
  padding: 0 6%; }

.partneri h3 {
  text-align: center; }


//FOOTER-----------------------------------------
footer {
  background-color: #231f20; }

.foot_links {
  padding-left: 11%;
  margin-top: 2%; }

.foot_links h4 {
  letter-spacing: 1px;
  margin-bottom: 4%;
  font-weight: bold; }

.foot_links a {
  text-decoration: none;
  line-height: 30px;
  color: #d61f21;
  &:hover {
    color: #a70002; } }

.foot_img {
  margin-top: 2%;
  width: 27%; }

.foot_img_fb {
  width: 67%;
  position: absolute;
  top: 0%;
  left: 32%;
  margin-top: 12.7%; }

.foot_form {
  margin-top: 2%;
  margin-left: 2%;
  h4 {
    font-weight: bold; } }

.form-group {
  margin-bottom: 5px; }

.footer_input {
  background-color: #3e3e3e;
  border: 1px solid #231f20;
  color: white;
  border-radius: 0;
  width: 85%; }

.form-control {
  height: 45px;
  border-radius: 0;
  font-family: Play,sans-serif !important; }

.form-control:focus {
  border-color: #5f5f5f;
  box-shadow: none;
  -webkit-box-shadow: none; }

.footer_send {
  font-family: Play,sans-serif !important;
  background-color: #d61f21;
  padding: 3.5% 6%;
  border-radius: 0;
  text-transform: uppercase;
  margin-left: 52.2%;
  margin-top: 2%;
  font-weight: 600;
  &:hover {
    background-color: #a70002;
    transition: 0.2s; } }

.lista {
  margin-top: 1%;
  padding: 0 3%;
  background-color: #000000;
  text-align: center; }

.lista p {
  margin: 7px 0 10px; }

.lista a {
  text-decoration: none;
  color: white;
  transition: 0.5s;
  &:hover {
    color: #d61f21;
    transition: 0.5s; } }

// PONUKA --------------------------------
.ponuka {
  margin-top: 2%; }

.ponukos {
  margin-left: 1%; }

.main_ponuka {
  position: absolute;
  top: 50%;
  left: 17%;
  width: 65%;
  text-align: center; }


.ponuka_nadpis {
  //position: absolute
  //top: 25%
  //left: 43%
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  font-size: 273%; }


.zemne_tabulka {
  width: 30%; }



//  padding-left: 0
//  padding-right: 0

//.huc
//  padding-left: 0
//  padding-right: 0

// GALERIA -------------------------------
.galerka {
	//transition: 0.3s
	&:hover {
        opacity: 0.9; } }

.gal-detail {
  //overflow: hidden
  a {
    text-decoration: none;
    color: white; } }

//REFERENCIE ---------------------------------------

.referencie img {
  margin-top: 5%;
  &:hover {
    opacity: 0.8; } }

// KONTAKT --------------------------------
.kontakt {
  margin-top: 1%;
  padding: 0;
  text-align: center; }

.kontakt_row {
  margin-left: 0%;
  margin-right: 0%; }

.kontakt_info {
  margin-top: -1%;
  margin-left: 15%;
  width: 35%; }

.kontakt_formular {
  margin-top: 0.5%;
  padding-right: 1%;
  border: 1px solid gray;
  background-color: gray;
  width: 35%;
  border-radius: 5px;
  background-color: #231f20; }

.kontakt_formular h2 {
  margin-top: 25px;
  color: white; }

.contact_input {
  background-color: #3e3e3e;
  border: 1px solid #3e3e3e;
  color: #fff; }

.kontakt h3 {
  color: #dd2022;
  font-weight: 600; }

.kontakt h4 {
  margin-bottom: 2%;
  line-height: 1.5; }

.haj {
  margin-top: 7%; }

.kontakt_phone a {
  margin-bottom: 1% !important; }

.kontakt_phone, .kontakt_mail {
  background-color: #dd2022;
  color: #ffffff;
  padding: 2.9% 6.1%; }

.kontakt_buttons {
  margin-top: 6%; }


.kontakt_mail {
  margin-left: 0.8%; }

.kontakt_phone {}

.kontakt a {
  text-decoration: none;
  &:hover {
    color: #ffffff;
    background-color: #a70002;
    transition: 0.2s; } }

.kontakt_mapa {
  width: 100%;
  margin-top: 3%; }

.zoom_kontakt_mapa {
  width: 100%;
  display: none; }

#footer_formular {
  font-family: 'Play', sans-serif !important; }


// OBJEDNAT --------------------------------

.order_rovno {
  margin-bottom: 2%;
  margin-top: 40px; }

.order_rovno h4 {
  margin-top: 0; }

.order_rovno label {
  font-weight: normal;
  line-height: 30px; }

.order_send {
  background-color: #d61f21;
  padding: 2.5% 4%;
  font-weight: 600;
  border-radius: 0;
  text-transform: uppercase;
  transition: 0.2s;
  color: white;
  font-family: 'Play', sans-serif !important;
  &:hover {
    background-color: #a70002;
    transition: 0.2s; } }

.order_send_kontakt {
  font-family: Play,sans-serif !important;
  background-color: #d61f21;
  padding: 2.5% 4%;
  color: #ffffff;
  font-weight: 600;
  border-radius: 0;
  text-transform: uppercase;
  transition: 0.2s;
  margin-top: 1%;
  width: 100%;
  margin-bottom: 23px;
  height: 50px;
  &:hover {
    background-color: #a70002;
    transition: 0.2s; } }

.navbar-toggle {
  margin-top: 0;
  margin-right: 0;
  border-radius: 0; }


.spracovanie a {
  text-decoration: none;
  &:hover {
    text-decoration: none; } }

// tabulka

table.zemne_tabulka tr:nth-child(odd) {
  background: #f0eded; }

.zemne_tabulka td {
  padding: 5px 10px; }






/* Hide table headers (but not display: none, for accessibility) */
//table.zemne_tabulka th
//  position: absolute
//  top: -9999px
//  left: -9999px
//  display: block




////Label the data
////
////table.standards td:nth-of-type(1):before  content: ""
////table.standards td:nth-of-type(2):before  content: ""
////table.standards td:nth-of-type(3):before  content: ""
////table.standards td:nth-of-type(4):before  content: ""





//MEDIA QUERIES -------------------------------

@media screen and (min-width: 1700px) and (max-width: 2600px) {
  .header_bg h3 {
    font-size: 30px; } }

@media screen and (min-width: 2421px) and (max-width: 2600px) {
  .navbar-nav>li>a {
    padding-top: 16px;
    padding-bottom: 16px; }

  .main_ponuka {
    top: 59%; }

  .downrow_nadpis {
    top: 73.8%; }



  .cover {
    top: 28%; } }

@media screen and (min-width: 2301px) and (max-width: 2420px) {
  .navbar-nav>li>a {
    padding-top: 14px;
    padding-bottom: 14px; }

  .main_ponuka {
    top: 59%;
 }    //left: 44%

  .downrow_nadpis {
    top: 73.8%; }

  .cover {
    top: 27%; } }

@media screen and (min-width: 2201px) and (max-width: 2300px) {
  .navbar-nav>li>a {
    padding-top: 12px;
    padding-bottom: 12px; }

  .main_ponuka {
    top: 57%; }

  .downrow_nadpis {
    top: 72.8%; }

  .cover {
    top: 26%; } }

@media screen and (min-width: 2051px) and (max-width: 2200px) {
  .navbar-nav>li>a {
    padding-top: 11px;
    padding-bottom: 11px; }

  .main_ponuka {
    top: 57%; }

  .downrow_nadpis {
    top: 72.8%; }

  .cover {
    top: 25%; } }

@media screen and (min-width: 1900px) and (max-width: 2050px) {
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px; }

  .main_ponuka {
    top: 55%; }

  .downrow_nadpis {
    top: 72.8%; }

  .cover {
    top: 25%; } }

@media screen and (min-width: 1800px) and (max-width: 1899px) {
  .navbar-nav>li>a {
    padding-top: 9px;
    padding-bottom: 9px; }

  .main_ponuka {
    top: 54%; }

  .downrow_nadpis {
    top: 72.8%; }

  .cover {
    top: 25%; } }

@media screen and (max-width: 1700px) {
  .navbar-default .navbar-nav > li > a, .navbar-default .navbar-text {
    height: 36px; } }

@media screen and (min-width: 1680px) and (max-width: 1799px) {
  .navbar-nav>li>a {
    padding-top: 8px;
    padding-bottom: 8px; }

  .main_ponuka {
    top: 52%; }

  .cover {
    top: 25%; } }

@media (min-width: 1600px) {
  .kontakt_info {
    margin-top: 0%; } }

@media screen and (min-width: 1530px) and (max-width: 1679px) {
  .navbar-nav>li>a {
    padding-top: 6px;
    padding-bottom: 6px; }

  .main_ponuka {
    top: 51%; }

  .cover {
    top: 24%; } }

@media (min-width: 1423px) {
  .navbar {
    min-height: 1.1%; } }

@media (max-width: 1400px) {
  .mail, .call {
    display: none !important; }

  .main_ponuka {
    top: 47%; } }

@media (max-width: 1320px) {
  .main_ponuka {
    top: 45%; } }


@media (max-width: 1250px) {
  .el_logo {
    height: 16%; }

  .main_ponuka {
    top: 44%; }

  .downrow_nadpis {
    top: 70.8%; } }

@media (max-width: 1200px) {
  .logo {
    height: 7%; } }

@media (max-width: 1150px) {
  .el_logo {
    height: 18%; }

  .downrow_nadpis {
    top: 69.8%; } }

@media (max-width: 1050px) {
  .el_logo {
    height: 20%; }

  .kontakt_info {
    width: 37%; }

  .main_ponuka {
    top: 38%; }

  .mapa {
    display: none; }

  .zoom_mapa {
    display: block; }

  .kontakt_mapa {
    display: none; }

  .zoom_kontakt_mapa {
    display: block; } }


@media (max-width: 1030px) {
  .logo {
    height: 8%; }

  //.main-bar h1
  //  font-size: 200%

  .cover h1 {
    font-size: 300%;
    margin-bottom: -10%; }

  .cover h2 {
    font-size: 200%; }

  .zip h1 {
    margin-bottom: -26%; }

  .zip h2 {
    margin-left: -35%; }

  .rip h1 {
    margin-bottom: -16%; } }

@media (max-width: 991px) {
  .kontakt_formular {
    margin-top: 3%;
    margin-left: 20%;
    width: 60%; }

  .main_ponuka {
    top: 41%;
    font-size: 90%; }

  .downrow_nadpis {
    top: 69%; }

  .zemne_tabulka {
    width: 60% !important;
    border: none; }

  .partneri {
    padding: 0; } }



@media (max-width: 980px) {
  #medzera {
    display: none; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .footer_input {
    width: 90%; } }

@media screen and (min-width: 778px) and (max-width: 991px) {
  .logo {
    height: 9%; }

  .footer_input {
    width: 93%; } }

@media (max-width: 940px) {
  .el_logo {
    height: 22%; } }

@media (max-width: 910px) {
  .news {
    display: none !important; }

  .downrow_nadpis {
    top: 68%; } }

@media screen and (min-width: 768px) and (max-width: 777px) {
  .foot_links {
    padding-left: 10%; }

  .footer_input {
    width: 99%; } }

@media (max-width: 991px) {
  .kontakt_formular {
    padding-right: 0;
    padding: 1% 3%;
    margin-top: 3%;
    margin-left: 10%;
    width: 80%; }

  .kontakt_info {
    margin-top: -3%;
    margin-left: 0%;
    width: 100%; }

  .foot_row {
    margin-right: -90px;
    margin-left: -90px; } }



@media (max-width: 840px) {
  .el_logo {
    height: 24%; }

  .main_ponuka {
    font-size: 80%;
    top: 38%; }

  .downrow_nadpis {
    top: 67%; } }

@media (max-width: 780px) {
  .navbar {
    left: 20%; } }

@media (min-width: 768px) {
  .navbar-nav>li>a {
    vertical-align: middle;
    display: table-cell; } }

@media (max-width: 777px) {
  .foot_row {
    margin-right: -15px;
    margin-left: -15px; } }

@media (max-width: 767px) {
  .logo {
    width: 30%;
    height: auto; }

  .el_logo {
    width: 30%;
    height: auto; }


  //.tutu_auticka
  //  height: 176px
  #bs-example-navbar-collapse-1 {
    width: 90%;
    right: 0;
    left: 0;
    margin: 0 auto; }

  .navbar-nav {
    width: 100% !important; }
  .navbar li:nth-child(2)>a:after {
    padding-left: 10px; }
  .news a:before {
    padding-right: 10px; }
  .menu_level_1 {
    width: 100%;
    position: inherit;
    >li {
      padding-left: 0;
      padding-top: 1%;
      padding-bottom: 1%; }
    a {
      text-align: center; } }
  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: #231f20; }


  .main_ponuka {
    top: 43%;
    width: 90%;
    left: 6%; }

  .alter_navbar {
    margin-top: -17% !important; }

  .navbar {
    //left: 35%
    //top: 5.5%
    position: static;
    float: right;
    margin-right: 2%;
    margin-top: -40%; }

  .navbar-toggle .icon-bar {
    width: 28px;
    height: 3px; }

  .navbar-toggle {
    padding: 12px 11px !important; }

  .navbar-header {
    margin-left: 0%; }

  .navbar-toggle {
    padding: 7px 10px; }

  .navbar li {
    width: 100%;
    height: auto; }

  .navbar-nav>li>a {
    padding-top: 9px;
    padding-bottom: 11px; }

  .navbar-default .navbar-nav>.active>a {
    height: auto; }

  #medzery {
    display: none; }

  .jednat {
    background-color: #231f20 !important;
 }    //margin-top: -10px


  .news {
    display: inline-block !important;
    &:before {
      content: ''; } }

  .navbar-nav {
    margin: 0;
    width: 180px; }

  .navbar li {
    border-left: none; }

  .cover h1 {
    font-size: 200%;
    margin-bottom: -12%; }

  .cover h2 {
    font-size: 160%; }

  .zip h1 {
    margin-bottom: -39%; }

  .zip h2 {
    margin-left: -21%; }

  .rip h1 {
    margin-bottom: -22%; }

  .ponukos {
    margin-left: 21.5%; }

  .kuc {
    margin-top: 3%;
    width: 61%; }

  .partneri {
    padding: 0 13%; }

  footer {
    text-align: center; }

  .foot_links {
    padding-left: 0;
    padding-right: 0; }

  .foot_links h4 {
    margin-bottom: 2%; }

  .foot_img {
    margin-top: 0%;
    margin-left: 39.5%;
    width: 35%;
    margin-bottom: 12%; }

  .foot_img_fb {
    left: 0;
    margin-top: 0;
    margin-left: 0; }

  .foot_form {
    margin-top: 13%;
    padding-left: 10%;
    padding-right: 13%; }

  .footer_input {
    width: 100%; }

  .footer_send {
    margin-left: 0;
    padding: 2.5% 5%; }
  .respon {
    text-align: center;
    a {
      img {
        display: inherit;
        margin-bottom: 15px; } } } }

@media screen and (min-width: 601px) and (max-width: 991px) {
  .kontakt_mail, .kontakt_phone {
    padding: 2% 6.1%; } }

@media screen and (min-width: 601px) and (max-width: 767px) {
  .foot_img_fb {
    margin-top: 6%;
    margin-left: -3%; } }

@media (max-width: 700px) {
  .odvoz, .prenajom, .zemne {
    top: 78.25%;
    width: 25%; }

  .odvoz {
    left: 11%; }

  .prenajom {
    left: 63.1%; }

  .zemne {
    left: 37%; }

  .ponukos {
    margin-left: 21%; }

  .downrow_nadpis {
    display: none; } }

@media (max-width: 660px) {
  .navbar {
    left: 37%; }

  .logo {
    width: 32%; }

  .el_logo {
    width: 32%; }

  .ponukos {
    margin-left: 19%; } }

@media (max-width: 600px) {
  //.tutu_auticka
  ////height: 9em

  .auticka {
    display: none; }

  .tirak {
    display: none; }

  .riso_auticka {
    display: block; }

  .downrow_nadpis {
    display: block;
    top: 77%; }

  .resp_butons {
    display: block; }

  .odvoz, .zemne, .prenajom {
    display: none; }

  .resp_buton {
    display: block !important;
    width: 350px;
    margin: 0 auto;
    margin-top: 4%; }

  .main_ponuka {
    top: 37%; }

  .navbar {
    margin-top: -48%; }

  .logo {
    width: 38%; }

  .el_logo {
    width: 38%; }

  .cover {
    left: 23%;
    text-align: center;
    font-size: 140%; }

  .cover h1 {
    font-size: 160%;
    margin-bottom: -11%; }

  .cover h2 {
    font-size: 130%; }

  .zip {
    font-size: 140%;
    text-align: center;
    left: 40%; }

  .zip h1 {
    margin-bottom: -40%; }

  .zip h2 {
    margin-left: 0%; }

  .rip {
    //top: 27%
    left: 35%;
    text-align: center;
    font-size: 140%; }

  .rip h1 {
    margin-bottom: -24% !important; }

  .foot_img_fb {
    width: 100%;
    margin-top: 4%;
    margin-left: -19%; } }

@media (max-width: 520px) {
  .navbar {
    left: 48%; }

  .logo {
    width: 42%; }

  .el_logo {
    width: 42%; } }

@media (max-width: 500px) {
  .ponuka_nadpis {
    font-size: 230%; }

  .odvoz, .prenajom, .zemne {
    top: 74%;
    width: 30%; }

  .zemne {
    left: 33.5%; }

  .odvoz {
    left: 2%; }

  .prenajom {
    left: 65.1%; }

  .foot_img_fb {
    width: 100%;
    margin-left: -20%;
    margin-top: 5%; }

  .downrow_nadpis {
    top: 74%; }

  .cover {
    left: 18%; }

  .rip {
    left: 34% !important; }

  .zip {
    left: 40% !important; }

  .partneri {
    padding: 0; }

  .zemne_tabulka {
    width: 100% !important; } }

@media (max-width: 450px) {
  .navbar {
    left: 54%; }

  .logo {
    width: 48%;
    z-index: 999; }

  .el_logo {
    width: 48%; }

  //.tutu_auticka
  //  height: 7em

  .main_ponuka {
    top: 41%; }

  .ponuka_nadpis {
    font-size: 140%; }

  .menu-content-edit {
    margin-top: -7%; }

  .ponukos {
    margin-bottom: -10%; }

  .cover {
    left: 16%; }

  .cover h1 {
    font-size: 150%;
    margin-bottom: -13%; }

  .cover h2 {
    font-size: 120%; }

  .zip h1 {
    margin-bottom: -45%; }

  .rip {
    //top: 22%
    left: 32% !important; }

  .rip h1 {
    margin-bottom: -33%; } }


@media (max-width: 410px) {
  .resp_buton {
    display: block !important;
    width: 310px; }

  .downrow_nadpis {
    top: 71%; }

  .cover {
    left: 14%; } }

@media (max-width: 390px) {
  .cover {
    left: 16%; }

  .cover h1 {
    font-size: 150%;
    margin-bottom: -15%; }

  .cover h2 {
    font-size: 110%; }
  //margin-left: -21%

  .zip {
    left: 38% !important; }

  .zip h1 {
    margin-bottom: -45% !important; }

  .rip h1 {
    margin-bottom: -27% !important; }

  .rip h2 {
    margin-left: 0% !important; }


  .main-bar h1 {
    margin-top: 6%; }

  .navbar {
    left: 60%; }

  .logo, .el_logo {
    width: 54%; }

  .ponukos {
    margin-bottom: -12%; } }


@media (max-width: 370px) {
  .cover {
    left: 14%;
    font-size: 120%; }

  .resp_buton {
    width: 260px; }

  .downrow_nadpis {
    top: 70%; }

  .rip {
    top: 17% !important;
    left: 30% !important; } }

@media (max-width: 362px) {
  .kontakt_mail, .kontakt_phone {
    padding: 2.9% 3.1%; }

  .main-bar h1 {
    font-size: 150%; }

  .menu-content-edit {
    margin-top: -10%; }

  .cover {
    left: 13%; } }


@media (max-width: 340px) {
  .main-bar h1 {
    margin-top: 8%; }

  .navbar {
    left: 67%; }

  .logo, .el_logo {
    width: 63%; }

  .ponukos {
    margin-bottom: -15%; }

  .cover {
    left: 11%; } }

@media (max-width: 325px) {
  .zemne_tabulka {
    width: 105% !important; } }

@media (max-width: 320px) {
  .cover {
    left: 8%;
    top: 15%; }

  .rip {
    top: 15%!important;
    left: 28%!important; }

  .zip {
    left: 35% !important;
    top: 16%; }

  .downrow_nadpis {
    top: 67%; } }

@media screen and (min-width: 1000px) and (max-width: 1050px) {
  #medzera {
    width: 3.6em; } }

@media screen and (min-width: 1051px) and (max-width: 1100px) {
  #medzera {
    width: 6em; } }

@media screen and (min-width: 1101px) and (max-width: 1150px) {
  #medzera {
    width: 8em; } }

@media screen and (min-width: 1151px) and (max-width: 1200px) {
  #medzera {
    width: 11em; } }

@media screen and (min-width: 1201px) and (max-width: 1250px) {
  #medzera {
    width: 13em; } }

@media screen and (min-width: 1251px) and (max-width: 1300px) {
  #medzera {
    width: 15em; } }

@media screen and (min-width: 1301px) and (max-width: 1350px) {
  #medzera {
    width: 17.5em; } }

@media screen and (min-width: 1351px) and (max-width: 1400px) {
  #medzera {
    width: 20em; } }

@media screen and (min-width: 1451px) and (max-width: 1500px) {
  #medzera {
    width: 5em; } }

@media screen and (min-width: 1501px) and (max-width: 1550px) {
  #medzera {
    width: 6.5em; } }

@media screen and (min-width: 1551px) and (max-width: 1600px) {
  #medzera {
    width: 9em; } }

@media screen and (min-width: 1601px) and (max-width: 1650px) {
  #medzera {
    width: 11em; } }

@media screen and (min-width: 1651px) and (max-width: 1700px) {
  #medzera {
    width: 13em; } }

@media screen and (min-width: 1701px) and (max-width: 1750px) {
  #medzera {
    width: 15em; } }

@media screen and (min-width: 1751px) and (max-width: 1800px) {
  #medzera {
    width: 17em; } }

@media screen and (min-width: 1801px) and (max-width: 1850px) {
  #medzera {
    width: 20em; } }

@media screen and (min-width: 1851px) and (max-width: 1900px) {
  #medzera {
    width: 23em; } }

@media screen and (min-width: 1901px) and (max-width: 1950px) {
  #medzera {
    width: 25em; } }

@media screen and (min-width: 1951px) and (max-width: 2000px) {
  #medzera {
    width: 27.5em; } }

@media screen and (min-width: 2001px) and (max-width: 2050px) {
  #medzera {
    width: 30em; } }

@media screen and (min-width: 2051px) and (max-width: 2100px) {
  #medzera {
    width: 32em; } }

@media screen and (min-width: 2101px) and (max-width: 2150px) {
  #medzera {
    width: 35em; } }

@media screen and (min-width: 2151px) and (max-width: 2200px) {
  #medzera {
    width: 37em; } }

@media screen and (min-width: 2201px) and (max-width: 2250px) {
  #medzera {
    width: 40em; } }

@media screen and (min-width: 2251px) and (max-width: 2300px) {
  #medzera {
    width: 42em; } }

@media screen and (min-width: 2301px) and (max-width: 2350px) {
  #medzera {
    width: 44em; } }

@media screen and (min-width: 2351px) and (max-width: 2400px) {
  #medzera {
    width: 46.5em; } }

@media screen and (min-width: 2401px) and (max-width: 2450px) {
  #medzera {
    width: 49em; } }

@media screen and (min-width: 2451px) and (max-width: 2500px) {
  #medzera {
    width: 51em; } }

@media screen and (min-width: 2501px) and (max-width: 2550px) {
  #medzera {
    width: 54em; } }

@media screen and (min-width: 2551px) and (max-width: 2601px) {
  #medzera {
    width: 56em; } }
